import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import htmlDecode from "html-entities-decoder"



const RelatedCol3 = (props) => (
    <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink m-0 p-0 md:p-6 pt-12 related-col">
        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
            <Link to={ `/${props.post.slug}/`} className="flex flex-wrap no-underline hover:no-underline">
                <Img 
                    fluid={ props.post.featuredImage.node.imageFile.childImageSharp.fluid } 
                    alt={props.post.featuredImage.node.altText} 
                    className="h-64 w-full rounded-t pb-6" 
                />
                <h2 className="w-full font-bold text-xl text-gray-900 px-6 pb-4">{ htmlDecode(props.post.title)}</h2>

            </Link>
        </div>
    </div>
)

export default RelatedCol3
