import React from 'react'
import Logo from "../images/zvistra-vertical.png";



const ScrollingHeader = () => (
	<div id="scrollingHeader" className="bg-white fixed w-full z-10 pin-t hidden animated opacity-95">
		<div className="bg-white">
			<div className="flex flex-wrap items-center content-center px-4">
				<div className="flex w-1/2 justify-start text-white font-extrabold">
					<img className="w-40 m-auto block py-6" src={Logo} alt="horizontal-logo" />
				</div>
			</div>
		</div>

		<div id="progress" className="h-1 bg-white shadow progress-bar"></div>
	</div>
);

export default ScrollingHeader;