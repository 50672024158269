import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from '../components/layout'
import ScrollingHeader from '../components/ScrollingHeader'
import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";

import {
	EmailShareButton,
	FacebookShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	EmailIcon
} from "react-share";


function BlogPageTest(props) {
    const {
        data: {
            wpgraphql: {post},
        },
    } = props;

    function preloadImage(img)
	{
		const src = img.getAttribute("data-src");
		if(!src) {
			return;
		}
		img.src = src;
	}

	const imgOptions = {
		threshold: 0,
		rootMargin: "0px 0px 300px 0px"
	};

	useEffect(() => {

		/* Progress bar */
		//Source: https://alligator.io/js/progress-bar-javascript-css-variables/
		var h = document.documentElement,
		b = document.body,
		st = "scrollTop",
		sh = "scrollHeight",
		progress = document.querySelector("#progress"),
		related = document.getElementsByClassName("related"),
		scroll;

		var scrollpos = window.scrollY;
		var header = document.getElementById("scrollingHeader");
		var mainHeader = document.getElementById("headerContainer");
		var headerHack = true;

		function headerScroller() {
			/*Refresh scroll % width*/
			scroll = ((h[st] || b[st]) + ( related[0].scrollHeight )) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
			progress.style.setProperty("--scroll", scroll + "%");
	
			/*Apply classes for slide in bar*/
			scrollpos = window.scrollY;
		
			if (scrollpos > 300 && headerHack ) {
				header.classList.remove("hidden");
				header.classList.remove("fadeOutUp");
				header.classList.add("slideInDown");
				mainHeader.classList.add("hidden");
				headerHack = false;
			}
	
			if (scrollpos === 0 && !headerHack ) {
				header.classList.remove("slideInDown");
				header.classList.add("fadeOutUp");
				header.classList.add("hidden");
				mainHeader.classList.remove("hidden");
				headerHack = true;
			}
		}

		document.addEventListener("scroll", headerScroller );

		let imgObserver = new IntersectionObserver((entries , imgObserver) => {
			entries.forEach( entry => {
				if(!entry.isIntersecting) {
					return;
				}else{
					preloadImage(entry.target);
					imgObserver.unobserve(entry.target);
				}
			})
		},imgOptions)

		let images = document.querySelectorAll("[data-src]");
		images.forEach(image => {
			imgObserver.observe(image)
		})


		return () => {
			document.removeEventListener("scroll", headerScroller);
		}

	},[])


	function addLazyLoadHandler(content)
	{
		let lazyLoadedContent;
		lazyLoadedContent = content.replace( /src="/g , 'data-src="')
		lazyLoadedContent = lazyLoadedContent.replace( /srcset="/g , 'data-srcset="')
		return lazyLoadedContent;
	}


    function constructMetaData (post){
		let payload = {
			title: htmlDecode(post.seo.title),
			description: post.seo.metaDesc,
			canonical: `https://blog.zvistra.gr/${props.pageContext.category.slug}/${post.slug}/`,
			fbTitle: post.seo.opengraphTitle ?post.seo.opengraphTitle : post.seo.title,
			fbDescription:  post.seo.opengraphDescription ? post.seo.opengraphDescription : post.seo.metaDesc,
			fbUrl: `https://blog.zvistra.gr/${props.pageContext.category.slug}/${post.slug}`,
			fbType: "website",
			locale: "el",
			image: post.seo.opengraphImage ? post.seo.opengraphImage.sourceUrl : post.featuredImage.sourceUrl
		}
		return payload
	}
	
    return (
        <Layout metaData={ constructMetaData(post) } >
            <ScrollingHeader />
            <div className="container mx-auto ">
				<div className="mx-0">
					<div className="bg-white w-full pb-8 md:pb-12 md:pt-12 text-xl md:text-2xl text-grey-darkest leading-normal" >
						<div className="title-flex flex max-w-5xl mx-auto">
							<div className="title-container" >
								<div className="post-title">
									<h1>
										{ htmlDecode(post.title)}
									</h1>
								</div>
                                <div className="text-lg">
									<span className="pt-2" style={{color:"gray"}}>Χρόνος Ανάγνωσης: { post.postReadTime.postReadTime }'</span>
								</div>
								{/* <div className="mt-12 flex items-center">
									<div className="post-author-tag-image-container">
										<img className="post-author-image" src={post.author.avatar.url} />
									</div>
									<div className="text-base">
										Written by {post.author.name}
									</div>
								</div> */}
								<div>

								</div>
							</div>
							<div className="image-container w-full" >
								{ post.featuredImage && (
									<Img 
										fluid={ post.featuredImage.node.imageFile.childImageSharp.fluid } 
										alt={post.featuredImage.node.altText} 
										className="h-full w-full rounded-t pb-6" 
									/>
								)}
							</div>
						</div>
					</div>
				</div>
                <div>
                    <div className="sticky-container hidden lg:block">
                        <div>
							<FacebookShareButton url={`https://blog.zvistra.gr/${props.pageContext.category.slug}/${post.slug}/`}>
								<FacebookIcon size={32} round={true} />
							</FacebookShareButton>

							<WhatsappShareButton url={`https://blog.zvistra.gr/${props.pageContext.category.slug}/${post.slug}/`}>
								<WhatsappIcon  size={32} round={true} />
							</WhatsappShareButton>

							<EmailShareButton url={`https://blog.zvistra.gr/${props.pageContext.category.slug}/${post.slug}/`}>
								<EmailIcon size={32} round={true}/>
							</EmailShareButton>
                        </div>
                    </div>
                    <div className="csm-border-blog">
                        <div dangerouslySetInnerHTML={{__html: addLazyLoadHandler(post.content) }} />
                    </div>
                </div>
                <div className="related">
                    { post.relatedPosts && (
                        <RelatedPosts relatedPostsList={ post.relatedPosts.relatedPosts } />
                    )}
                </div>

            </div>

        </Layout>
    )
}

export default BlogPageTest

export const pageQuery = graphql`
    query GET_POST($id: ID!) {
        wpgraphql {
            post(id: $id) {
                slug
                title
                content
                author {
                    node{
                        name
                        avatar {
                            url
                        }
                    }
                }
                seo {
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                featuredImage {
                    node{
                        sourceUrl
                        altText
                        imageFile {
                            childImageSharp {
                                fluid{
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
				}
                postReadTime {
                    postReadTime
                }
                categories{
                    nodes{
                        slug
                        name
                    }
                }
                relatedPosts {
                    relatedPosts {
                        ... on WPGraphQL_Post {
                            slug
                            title
                            featuredImage{
                                node{
                                    sourceUrl
                                    altText
                                    imageFile {
                                        childImageSharp {
                                            fluid{
                                            base64
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            sizes
                                            }
                                        }
                                    }
                                }
                                
							}
							categories {
								edges {
								  node {
									slug
									name
								  }
								}
							}
                        }
                    }
                }
            }
        }
    }
`
